<template>
  <div
    class="navbar-container d-flex content align-items-center px-1 py-50"
    :style="`${ navBarBackgroundColor ? `background-color: ${navBarBackgroundColor} !important` : ''}`"
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav">
      <li class="nav-item d-flex">
        <b-link
          class="nav-link d-flex d-xl-none"
          :class="isMobileView ? 'align-items-center' : ''"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            class="d-inline"
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- MD Breakpoint -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-md-flex">
      <div
        v-if="meData"
        class="d-flex flex-row"
      >
        <div class="d-flex-center justify-content-start text-uppercase">
          <span class="font-weight-bold d-block text-nowrap text-white pb-0 mr-50">
            {{ meData.agency.agencyName }}
          </span>
          <em
            class="font-weight-bold text-warning text-nowrap"
            @dblclick="onHiddenClickHandle"
          >({{ meData.agency.agencyCode }})</em>
        </div>
        <div
          v-if="!isRoleF1"
          class="d-flex-center gap-2 text-white ml-50"
        >
          <em :class="`${textBalanceClass} ${appBreakPoint === 'lg' ? 'font-small-3' : ''}`">
            <span class="d-none d-lg-inline">{{ $t('accountsPayableTemp') }}:</span>
            <em>
              {{ formatCurrency(meData.agency.balance) }}
              <span class="d-none d-lg-inline">VND</span>
            </em>
            <em class="text-white text-nowrap">
              / {{ formatCurrency(meData.agency.limitCredit) }}
              <span class="d-none d-lg-inline">VND</span>
            </em>
          </em>

          <em
            v-if="meData.agency?.lockBalance"
            :class="`text-danger ${appBreakPoint === 'lg' ? 'font-small-3' : ''}`"
          >
            <span class="d-none d-lg-inline">{{ $t('lockBalance') }}:</span>
            <em>
              {{ formatCurrency(meData.agency?.lockBalance) }}
              <span class="d-none d-lg-inline">VND</span>
            </em>
          </em>

          <BButton
            v-b-tooltip.hover.v-info
            :title="$t('reloadAccountsPayable')"
            variant="flat-primary"
            class="p-25 rounded-circle"
            @click="handleReload"
          >
            <feather-icon
              icon="RotateCwIcon"
              size="16"
              :class="loadingReload ? 'spinner-1s' : ''"
              class="text-white"
            />
          </BButton>
          <BButton
            v-b-tooltip.hover.v-info
            :title="$t('topup.createTopup')"
            variant="flat-primary"
            class="p-25 rounded-circle"
            @click="handleCreateTopup"
          >
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="text-white"
            />
          </BButton>
        </div>
      </div>
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>

    <!-- mobile breakpoint -->
    <div
      v-if="!isRoleF1 && meData"
      class="d-flex-center d-md-none"
    >
      <div :class="`${textBalanceClass} d-flex flex-wrap gap-1`">
        <em class="text-white text-nowrap mr-25 d-none d-sm-inline">{{ $t('accountsPayableTemp') }}:</em>
        <div
          v-if="meData"
          style="line-height: normal;"
          :class="isMobileView ? 'font-small-2 d-flex-center flex-wrap gap-1' : ''"
        >
          <em :class="isMobileView ? 'font-small-2 text-nowrap' : ''">
            {{ formatCurrency(meData.agency.balance) }}
          </em>

          <em
            class="text-white text-nowrap"
            :class="isMobileView ? 'font-small-2' : ''"
          >/ {{ formatCurrency(meData.agency.limitCredit) }}
          </em>

          <em
            v-if="meData.agency?.lockBalance"
            class="text-danger"
          >
            {{ formatCurrency(meData.agency?.lockBalance) }}
          </em>
        </div>
      </div>
      <BButton
        v-b-tooltip.hover.v-info
        :title="$t('reloadAccountsPayable')"
        variant="flat-primary"
        class="p-25 rounded-circle"
        @click="handleReload"
      >
        <feather-icon
          icon="RotateCwIcon"
          size="14"
          :class="loadingReload ? 'spinner-1s' : ''"
          class="text-white"
        />
      </BButton>
      <BButton
        v-b-tooltip.hover.v-info
        :title="$t('topup.createTopup')"
        variant="flat-primary"
        class="p-25 rounded-circle"
        @click="handleCreateTopup"
      >
        <feather-icon
          icon="DollarSignIcon"
          size="14"
          class="text-white"
        />
      </BButton>
    </div>

    <CreateTopupModal
      :sender="senderData"
      :recipient="recipientData"
      :is-empty-bank-accounts="isEmptyBankAccounts"
      @show="handleOpenTopupModal"
    />

    <b-navbar-nav
      class="nav d-flex-center ml-auto flex-md-nowrap"
      :class="isMobileView ? 'flex-nowrap' : ''"
    >
      <BankSellingRate />
      <locale />
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <!-- <search-bar /> -->
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BButton, VBTooltip,
} from 'bootstrap-vue'
import {
  computed, ref, watch,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

import store from '@/store'
import { formatCurrency } from '@/@core/utils/filter'
import { apiAgencies } from '@/api'
import { getUserData } from '@/api/auth/utils'
import { SENDER_TYPES } from '@/constants/topup'
import { env } from '@/libs/env'

import Locale from './components/Locale.vue'
import NotificationDropdown from './components/notification-dropdown/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    BButton,
    BNavbarNav,
    Locale,
    NotificationDropdown,
    UserDropdown,
    CreateTopupModal: () => import('@topup/topup-popup/CreateTopupModal.vue'),
    BankSellingRate: () => import('./components/BankSellingRate.vue'),
  },

  directives: {
    Ripple, 'b-tooltip': VBTooltip,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  setup() {
    const meData = computed(() => store.getters['userStore/getMeData'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const agencyManager = computed(() => getUserData().employeeData?.agency)
    const loadingReload = ref(false)
    const resCheckCredit = ref(null)

    async function checkCreditForPayment(agency) {
      const payload = {
        id: agency.id,
        amount: 0,
      }
      apiAgencies.checkCredit(payload.id, { amount: payload.amount }).then(res => {
        resCheckCredit.value = res.data
      })
    }

    async function handleReload() {
      loadingReload.value = true
      await store.dispatch('userStore/fetchMeData')
      checkCreditForPayment(agencyManager.value)
      loadingReload.value = false
    }

    watch(() => agencyManager.value, agencyData => {
      if (agencyData) {
        if (agencyData.id !== 1000000) {
          checkCreditForPayment(agencyData)
        } else {
          resCheckCredit.value = 0
        }
      }
    }, { immediate: true })

    const textBalanceClass = computed(() => {
      if (!resCheckCredit.value) return ''
      if (resCheckCredit.value.isOverLimit || resCheckCredit.value.isWarningLimitPercent) return 'text-danger'
      return resCheckCredit.value.isWarning ? 'text-warning' : 'text-success'
    })

    // FOR create topup
    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const senderData = ref({})
    const recipientData = ref({})
    const isEmptyBankAccounts = ref(false)
    const setSenderData = () => {
      senderData.value = {
        id: agencyData.value.id,
        agencyCode: agencyData.value.agencyCode,
        agencyName: agencyData.value.agencyName,
        type: SENDER_TYPES.AGENCY,
      }
    }

    const setRecipientData = () => {
      recipientData.value = {
        id: agencyData.value.parentAgency.id,
        agencyCode: agencyData.value.parentAgency.agencyCode,
        agencyName: agencyData.value.parentAgency.agencyName,
        bankAccounts: agencyData.value.parentAgency?.bankAccs ?? agencyData.value.parentAgency?.bankAccounts ?? [],
      }
      if (!(agencyData.value?.parentAgency?.bankAccs?.length ?? agencyData.value?.parentAgency?.bankAccounts?.length)) {
        isEmptyBankAccounts.value = true
      }
    }

    const handleOpenTopupModal = () => {
      setSenderData()
      setRecipientData()
    }
    function handleCreateTopup() {
      this.$bvModal.show('create-topup-modal')
    }

    const { isProduction } = env
    function onHiddenClickHandle() {
      if (!env?.isDevelopment) return
      // console.log('switch tpv', isTurnOnAllFunctionEnv.value)
      const isTurnOnAllFunctionEnv = localStorage.getItem('isTurnOnAllFunctionEnv') === 'true'
      localStorage.setItem('isTurnOnAllFunctionEnv', !isTurnOnAllFunctionEnv)
      window.location.reload()
    }

    const navBarBackgroundColor = env?.navBarBackgroundColor || ''
    return {
      meData,
      isRoleF1,
      resCheckCredit,
      formatCurrency,
      handleReload,
      loadingReload,

      handleCreateTopup,
      senderData,
      recipientData,
      isEmptyBankAccounts,
      textBalanceClass,
      handleOpenTopupModal,
      isProduction,
      onHiddenClickHandle,
      navBarBackgroundColor,
    }
  },
}
</script>

<style lang="scss">
.alert-system-class > .tooltip-inner {
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
  max-width: 600px;
}

// #alert-tooltip {
//   animation: endless_animation 10s linear infinite;
// }

#alert-system-popover {
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
  max-width: 600px;
}
</style>
